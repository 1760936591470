import React from "react"
import Layout from "../components/layout"
import MyNavLink from "../components/my-nav-link"
import plugins from '../../content/pluginsInfo'
import '../css/all_plugins.css'

export default  class AllPlugIns extends React.Component {
  componentWillMount() {
    // window.scrollTo(0,0)
  }

  render() {
    return (
      <Layout>
        <div className="pagewidth clearfix">
          <h1 className="page-title textcenter">全部插件</h1>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 first">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[0].poster+')'}}></div>
              </div>
              <div className="col2-1">
                <h3 className="plugin-title">{plugins[0].name}</h3>
                <p className="plugin-description">{plugins[0].desc}.</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[0].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 right-poster">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[1].poster+')'}}></div>
              </div>
              <div className="col2-1 first">
                <h3 className="plugin-title">{plugins[1].name}</h3>
                <p className="plugin-description">{plugins[1].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[1].path}>查看详情</MyNavLink></p>
              </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 first">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[2].poster+')'}}></div>
              </div>
              <div className="col2-1">
                <h3 className="plugin-title">{plugins[2].name}</h3>
                <p className="plugin-description">{plugins[2].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[2].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 right-poster">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[3].poster+')'}}></div>
              </div>
              <div className="col2-1 first">
                <h3 className="plugin-title">{plugins[3].name}</h3>
                <p className="plugin-description">{plugins[3].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[3].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 first">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[4].poster+')'}}></div>
              </div>
              <div className="col2-1">
                <h3 className="plugin-title">{plugins[4].name}</h3>
                <p className="plugin-description">{plugins[4].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[4].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 right-poster">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[5].poster+')'}}></div>
              </div>
              <div className="col2-1 first">
                <h3 className="plugin-title">{plugins[5].name}</h3>
                <p className="plugin-description">{plugins[5].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[5].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 first">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[6].poster+')'}}></div>
              </div>
              <div className="col2-1">
                <h3 className="plugin-title">{plugins[6].name}</h3>
                <p className="plugin-description">{plugins[6].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[6].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
          <section className="plugin-section">
            <div className="row clearfix">
              <div className="col2-1 right-poster">
                <div className="plug-in-poster" style={{backgroundImage:'url('+plugins[7].poster+')'}}></div>
              </div>
              <div className="col2-1 first">
                <h3 className="plugin-title">{plugins[7].name}</h3>
                <p className="plugin-description">{plugins[7].desc}</p>
                <p><MyNavLink className="more-info-plugin" to={plugins[7].path}>查看详情</MyNavLink></p>                        </div>
            </div>
          </section>
        </div>


      </Layout>
    )
  }
}




